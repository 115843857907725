import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";
import { isNumber, toNumber, remove, each, indexOf, uniq, difference, union, cloneDeep } from 'lodash'

import { Button, Spin, Typography, Alert, Row, Col, Statistic, message, Divider, Modal, Table, Image, Tabs, Popconfirm, Input, InputNumber, Radio, Tooltip, Tag, Space } from 'antd';
import { close_number_tag, ELM_STANDARD, MENU_ITEMS, order_tag } from '../../util/options';
import MemberService from '../../services/user/member.service';
import { DATE_FORMAT, DB, MEMBER_INTERVAL_UPDATE, NUMBER_FORMAT } from '../../util/constant';

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import { CheckOutlined, ClearOutlined, ClockCircleOutlined, DeleteOutlined, HistoryOutlined, LeftOutlined, PlusOutlined, QuestionCircleOutlined, RocketOutlined, SaveOutlined, SolutionOutlined, SyncOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useUserContext } from '../../contexts/UserContext';
import OrderService from '../../services/system/order.service';
import OrderDetail from '../common/modal/OrderDetail';
import { LOTTO_STYLE } from '../../util/style_objects';

const { TabPane } = Tabs;
const { Title } = Typography;
const { Countdown } = Statistic;
const inputPattern = {
    pattern: "[0-9]*",
}

const inputNumberConfig = {
    min: 0,
    controls: false,
    precision: 0,
}


const Bet = () => {
    const [updatedAt, setUpdatedAt] = useState(null)
    const { user, userAction } = useUserContext();
    const navigate = useNavigate()
    const [lotto, setLotto] = useState(undefined);
    const [lottoResult, setLottoResult] = useState(undefined);
    const [recentOrder, setRecentOrder] = useState(undefined);
    const [lottoClosedNumber, setLottoClosedNumber] = useState(undefined);

    const { lottoDateId } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOrderVisible, setIsModalOrderVisible] = useState(false);
    const [modalDataSource, setModalDataSource] = useState([])
    const [isModalOrderDetailVisible, setIsModalOrderDetailVisible] = useState(false);
    const [modalOrderDetailData, setModalOrderDetailData] = useState({})


    const [betNumber, setBetNumber] = useState(undefined)
    const [numberArray, setNumberArray] = useState([])
    const [removedNumberArray, setRemovedNumberArray] = useState([])
    const [betAmount1, setBetAmount1] = useState(undefined)
    const [betAmount2, setBetAmount2] = useState(undefined)
    const [betAmount3, setBetAmount3] = useState(undefined)

    const [order, setOrder] = useState([])
    const [orderNo, setorderNo] = useState(0)
    const [remark, setRemark] = useState("")
    const [currentMode, setCurrentMode] = useState("1")

    // mode 4 limit 7
    const [countMode4, setCountMode4] = useState(0)

    // for mode6
    const [subMode, setSubMode] = useState("1")
    const [subMode2, setSubMode2] = useState("1")
    const [selectedNumbersMode6, setSelectedNumbersMode6] = useState([])

    const updateNumberMode6 = (number) => {
        // if item not exists in array, add
        if (selectedNumbersMode6.indexOf(number) === -1) {
            if (selectedNumbersMode6.length === 7) {
                // message error, return
                message.error({ content: `กรุณาเลือกตัวเลขที่ต้องการจับวิน ${subMode === "1" ? "2" : "3"} - 5 ตัวเลข` })
                return
            }
            setSelectedNumbersMode6([...selectedNumbersMode6, number])
        }
        // if item exists in array, remove that number
        else {
            const clone = [...selectedNumbersMode6]
            remove(clone, (s) => {
                return s === number
            })
            setSelectedNumbersMode6(clone)
        }
    }

    const calculateMode6 = () => {
        if (selectedNumbersMode6.length < 2 || selectedNumbersMode6.length > 5) {
            // message error, return
            message.error({ content: `กรุณาเลือกตัวเลขที่ต้องการจับวิน ${subMode === "1" ? "2" : "3"} - 5 ตัวเลข` })
            return
        }

        const result = []
        const clone = [...selectedNumbersMode6]
        // order
        clone.sort((a, b) => {
            return a - b
        })

        let i = 0
        if (subMode === "1") {
            if (subMode2 === "1") {
                for (const item of clone) {
                    // remove first element
                    let workingArr = clone.slice(i + 1)
                    for (const item2 of workingArr) {
                        if (item === item2) {
                            continue;
                        } else {
                            result.push(`${item}${item2}`)
                        }
                    }
                    i++
                }
            } else if (subMode2 === "2") {
                for (const item of clone) {
                    // remove first element
                    let workingArr = clone.slice(i)
                    for (const item2 of workingArr) {
                        result.push(`${item}${item2}`)
                    }
                    i++
                }
            }
        }
        else if (subMode === "2") {
            let j = 0
            for (const item of clone) {
                let workingArr1 = clone.slice(i)
                for (const item2 of workingArr1) {
                    let workingArr2 = clone.slice(j)
                    for (const item3 of workingArr2) {
                        if (subMode2 === "2") {
                            result.push(`${item}${item2}${item3}`)
                        } else {
                            if (item === item2 || item === item3 || item2 === item3) {
                                continue;
                            } else {
                                result.push(`${item}${item2}${item3}`)
                            }
                        }
                    }
                    j++
                }
                j = i + 1
                i++;
            }
        }

        // add result to numberArray
        setNumberArray(result)
    }

    const reverseMode6Numbers = () => {
        const numberArrayClone = [...numberArray]
        
        numberArrayClone.forEach(numberString => {
            const reversedNumberString = numberString.split('').reverse().join('')

            if (!numberArrayClone.includes(reversedNumberString)) {
                numberArrayClone.push(reversedNumberString)
            }
        });

        setNumberArray(numberArrayClone)
    }

    const [tabTarget, setTabTarget] = useState(undefined)

    const [maxBet1, setMaxBet1] = useState(undefined)
    const [maxBet2, setMaxBet2] = useState(undefined)
    const [maxBet3, setMaxBet3] = useState(undefined)

    const [minBet1, setMinBet1] = useState(undefined)
    const [minBet2, setMinBet2] = useState(undefined)
    const [minBet3, setMinBet3] = useState(undefined)

    const [isThai, setIsThai] = useState(false)


    const pasteNumber = (mode, e) => {
        // sample: 23+24 , 25 ,26-,27-123/28.29=30-31 1 2 3
        e.preventDefault()
        let clipboard = e.clipboardData.getData('Text')
        let clipboardArr = clipboard.split(/(?:,|\+|-|\/|\.|=|\r\n| |\n)+/)

        for (const c of clipboardArr) {
            if (!isNaN(c * 1) && isNumber(c * 1) && c.length === mode) {
                addNumberToOrder(c);
            }
        }
    }


    const numberInputHandler = (e) => {
        if (e.target.validity.valid) {
            let value = e.target.value;
            if (value.length === 2) {
                setBetNumber(null)
                addNumberToOrder(value);
            }
            else {
                setBetNumber(value)
            }
        }
    }

    const numberInputHandler2 = (e) => {
        if (e.target.validity.valid) {
            let value = e.target.value;
            if (value.length === 3) {
                setBetNumber(null)
                addNumberToOrder(value);
            }
            else {
                setBetNumber(value)
            }
        }
    }

    const numberInputHandler3 = (e) => {
        if (e.target.validity.valid) {
            let value = e.target.value;
            if (value.length === 3) {
                setBetNumber(null)
                addNumberToOrderMode3(value);
            }
            else {
                setBetNumber(value)
            }
        }
    }

    const numberInputHandler4 = (e) => {
        if (e.target.validity.valid) {
            let value = e.target.value;
            setBetNumber(null)
            if (countMode4 === 7) {
                message.error({ content: `รูดได้ครั้งละ 7 ตัวเลข` })
                return
            }
            addNumberToOrderMode4(value);
            // count this mode
            setCountMode4(countMode4 + 1)
        }
    }

    const numberInputHandler5 = (e) => {
        if (e.target.validity.valid) {
            let value = e.target.value;
            setBetNumber(null)
            addNumberToOrderMode5(value);
        }
    }


    const isAbleToAddMoreNumber = () => {
        const numbersWithoutRemoved = removeFromNumberArray([...numberArray], [...removedNumberArray])
        if (numbersWithoutRemoved.length >= 10000) {
            message.error({ content: "ใส่เลขได้ไม่เกิน 10000 เลข", key: "numberArray" })
            return false;
        } else {
            return true;
        }
    }

    const addNumberToOrder = (value) => {
        if (isAbleToAddMoreNumber()) {
            setNumberArray(numberArray => [...numberArray, value])
        }
    }

    const addNumberToOrderMode3 = (n) => {
        if (isAbleToAddMoreNumber()) {
            let result = [n.charAt(0) + n.charAt(1) + n.charAt(2)
                , n.charAt(0) + n.charAt(2) + n.charAt(1)
                , n.charAt(1) + n.charAt(0) + n.charAt(2)
                , n.charAt(1) + n.charAt(2) + n.charAt(0)
                , n.charAt(2) + n.charAt(0) + n.charAt(1)
                , n.charAt(2) + n.charAt(1) + n.charAt(0)]
            uniq(result, [n]).forEach(u => {
                addNumberToOrder(u)
            });
        }
    }

    const addNumberToOrderMode4 = (n) => {
        if (isAbleToAddMoreNumber()) {
            let results = []
            for (let i = 0; i < 10; i++) {
                results[i] = `${i}${n}`
            }
            for (let i = 0; i < 10; i++) {
                if (n === `${i}`) {
                    continue;
                }
                results[i + 10] = `${n}${i}`
            }
            results.forEach(u => {
                addNumberToOrder(u)
            });
        }
    }

    const addNumberToOrderMode5 = (n) => {
        if (isAbleToAddMoreNumber()) {
            const clone = [...numberArray]
            setNumberArray(union(clone, [n]))
        }
    }

    const putDoubleNumbers = () => {
        let doubleNumbers = ["00", "11", "22", "33", "44", "55", "66", "77", "88", "99"]
        if (isAbleToAddMoreNumber()) {
            setNumberArray(numberArray => [...numberArray, ...doubleNumbers])
        }
    }

    const putTripleNumbers = () => {
        let doubleNumbers = ["000", "111", "222", "333", "444", "555", "666", "777", "888", "999"]
        if (isAbleToAddMoreNumber()) {
            setNumberArray(numberArray => [...numberArray, ...doubleNumbers])
        }
    }

    const countDownEnd = () => {
        message.info({ content: 'ตลาดปิด กลับไปหน้าเลือกหวย..', duration: 60 });
        setTimeout(() => {
            navigate(MENU_ITEMS.homeMember.path)
        }, 2000)
    }

    const renderCloseTime = () => {
        return lotto?.closeAt && <Countdown prefix={"เหลือเวลา"} value={Date.now() + 1000 + 1000 * dayjs(lotto.closeAt).diff(dayjs(), 's', true)} onFinish={countDownEnd} valueStyle={{ color: '#cf1322' }} />
    }

    const tabChange = (activeKey) => {
        if (activeKey !== currentMode) {
            setBetNumber(null)
            if (numberArray.length > 0) {
                setTabTarget(activeKey)
                setIsModalVisible(true)
            } else {
                resetBetEntry()
                setCurrentMode(activeKey)
            }
        }

    }

    const removeFromNumberArray = (numbers, toBeRemoved) => {
        each(toBeRemoved, function (value) {
            numbers.splice(numbers.indexOf(value), 1);
        });
        return numbers;
    }


    const removeNumberFromArray = (e) => {
        const { n } = e.target.dataset
        if (!e.target.classList.contains("removed-betNumber")) {
            setRemovedNumberArray(removedNumberArray => [...removedNumberArray, n])
        } else {
            const cloneRemovedNumberArray = [...removedNumberArray]
            cloneRemovedNumberArray.splice(indexOf(cloneRemovedNumberArray, n), 1)
            setRemovedNumberArray(cloneRemovedNumberArray)
        }
        e.target.classList.toggle("removed-betNumber")
    }

    const getSumBetAmount = (order) => {
        if (order.length > 0) {
            let sumPrice = 0;
            let numberCount = 0;
            let orderSum = 0
            let result = 0;
            order.forEach(b => {
                sumPrice = b.betAmount1 + b.betAmount2 + b.betAmount3;
                numberCount = b.numberArray.length
                orderSum = numberCount * sumPrice
                result += orderSum;
            });
            return result
        }
        return 0;
    }

    const sumBetAmount = useMemo(() => getSumBetAmount(order), [order]);


    const putInvertNumbers = () => {
        const numbersWithoutRemoved = removeFromNumberArray([...numberArray], [...removedNumberArray])
        if (currentMode === "1") {
            numbersWithoutRemoved.forEach(n => {
                if (n.charAt(1) + n.charAt(0) !== n) {
                    addNumberToOrder(n.charAt(1) + n.charAt(0))
                }
            });

        } else if (currentMode === "2") {
            numbersWithoutRemoved.forEach(n => {
                let result = [n.charAt(0) + n.charAt(2) + n.charAt(1)
                    , n.charAt(1) + n.charAt(0) + n.charAt(2)
                    , n.charAt(1) + n.charAt(2) + n.charAt(0)
                    , n.charAt(2) + n.charAt(0) + n.charAt(1)
                    , n.charAt(2) + n.charAt(1) + n.charAt(0)]
                uniq(difference(result, [n])).forEach(u => {
                    addNumberToOrder(u)
                });
            });
        }
    }


    const renderNumberArea = () => {
        const numberArrayClone = [...numberArray]
        return <div className="betNumber-area">
            <Row gutter={[8, 8]} justify="space-between">
                <Col xs={20}>
                    <Row gutter={[8, 8]}>
                        {numberArrayClone.length > 0 &&
                            (numberArrayClone?.map((n, i) =>
                                <Col className='number-wrapper' data-n={n} onClick={removeNumberFromArray} key={`n-${i}`}>
                                    {n}
                                </Col>)
                            )}
                    </Row>
                </Col>
                <Col><Button type="error" icon={<ClearOutlined />} onClick={() => resetNumber()}>  ยกเลิก</Button> </Col>
            </Row>
        </div>
    }

    const areaNumberRenderer = () => {
        if (order.length > 0) {
            return order.map((b, i) =>
                <div key={`order-${i}`}>
                    <Divider />
                    <Row gutter={[8, 8]}>
                        <Col span={4} className="bill-area-detail">
                            <div>{b.modeDetail.mode}</div>
                            <div>{b.modeDetail.bet}</div>
                            {b.modeDetail.hasOwnProperty('key3') ?
                                <div>{b.betAmount1} x {b.betAmount3} x {b.betAmount2}</div>
                                : <div>{b.betAmount1} x {b.betAmount2}</div>}

                        </Col>
                        <Col span={16} className="bill-area-betNumber">
                            {b.numberArray?.join(" ")}
                        </Col>
                        <Col span={4} className="bill-area-btn">
                            <Button icon={<DeleteOutlined />} onClick={() => deleteFromOrder(b.orderNo)} />
                        </Col>
                    </Row>
                </div>
            )
        }

    }

    const focusChangeById = (e, id) => {
        if (e.key === 'Enter') {
            document.getElementById(`input-${id + 1}`).focus();
        }
        if (e.code === 'Space') {
            putInvertNumbers()
        }

    }

    const inputDoubleNumbers = () => {
        return <Button icon={<PlusOutlined />} size="small" className="playmode-btn"
            onClick={putDoubleNumbers}>
            เลขเบิ้ล
        </Button>
    }

    const inputTripleNumbers = () => {
        return <Button icon={<PlusOutlined />} size="small" className="playmode-btn"
            onClick={putTripleNumbers}>
            เลขตอง
        </Button>
    }


    const inputNumber1 = (id) => {
        return <Input id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)} onPaste={(e) => pasteNumber(2, e)} maxLength="2" {...inputPattern} addonBefore="ใส่เลข" value={betNumber} onChange={numberInputHandler} />
    }

    const inputNumber2 = (id) => {
        return <Input id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)} onPaste={(e) => pasteNumber(3, e)} maxLength="3"  {...inputPattern} addonBefore="ใส่เลข" value={betNumber} onChange={numberInputHandler2} />
    }

    const inputNumber3 = (id) => {
        return <Input id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)} onPaste={(e) => pasteNumber(3, e)} maxLength="3"  {...inputPattern} addonBefore="ใส่เลข" value={betNumber} onChange={numberInputHandler3} />
    }

    const inputNumber4 = (id) => {
        return <Input id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)} onPaste={(e) => pasteNumber(2, e)} maxLength="1"  {...inputPattern} addonBefore="ใส่เลข" value={betNumber} onChange={numberInputHandler4} />
    }

    const inputNumber5 = (id) => {
        return <Input id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)} onPaste={(e) => pasteNumber(1, e)} maxLength="1"  {...inputPattern} addonBefore="ใส่เลข" value={betNumber} onChange={numberInputHandler5} />
    }

    const putInvertNumbersBtn = (nextId) => {
        return <Button onFocus={() => document.getElementById(`input-${nextId}`).focus()} icon={<SyncOutlined />} className="playmode-btn" onMouseDown={putInvertNumbers}>
            กลับเลข
        </Button>
    }

    const inputBetAmount1 = (title, id) => {
        return <InputNumber id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)}  {...inputNumberConfig} addonBefore={title} value={betAmount1} onChange={(v) => setBetAmount1(v)} />
    }

    const inputBetAmount2 = (title, id) => {
        return <InputNumber id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)}  {...inputNumberConfig} addonBefore={title} value={betAmount2} onChange={(v) => setBetAmount2(v)} />
    }

    const inputBetAmount3 = (title, id) => {
        return <InputNumber id={`input-${id}`} onKeyDown={(e) => focusChangeById(e, id)}  {...inputNumberConfig} addonBefore={title} value={betAmount3} onChange={(v) => setBetAmount3(v)} />
    }

    const btnAddOrder = (id, firstId) => {
        return <Button style={lotto?.lottos_fk ? LOTTO_STYLE[lotto.lottos_fk]?.details?.button : {}} onFocus={(e) => addToOrder(e, firstId)} onKeyDown={(e) => { if (e.key === 'Enter') addToOrder(e, firstId) }} id={`input-${id}`} type="primary" icon={<PlusOutlined />} onMouseDown={(e) => addToOrder(e, firstId)}>
            เพิ่มบิล
        </Button>
    }


    const addToOrder = (e, firstId) => {
        e.preventDefault()
        e.stopPropagation()

        const numbersWithoutRemoved = removeFromNumberArray([...numberArray], [...removedNumberArray])
        if (numbersWithoutRemoved.length > 0) {
            if (toNumber(betAmount1 > 0) || toNumber(betAmount2 > 0) || toNumber(betAmount3 > 0)) {
                let id = orderNo
                setorderNo(id + 1)
                const modeDetail = getModeDetail(currentMode, lotto.groupId === (DB.SYSTEM.LOTTO.GROUP.find((g) => g.name === 'หวยไทย'))?.id);
                let cloneBetAmount1 = toNumber(betAmount1) > 0 ? toNumber(betAmount1) : 0
                let cloneBetAmount2 = toNumber(betAmount2) > 0 ? toNumber(betAmount2) : 0
                let cloneBetAmount3 = toNumber(betAmount3) > 0 ? toNumber(betAmount3) : 0
                if (cloneBetAmount1 > maxBet1) cloneBetAmount1 = maxBet1
                if (cloneBetAmount2 > maxBet2) cloneBetAmount2 = maxBet2
                if (cloneBetAmount3 > maxBet3) cloneBetAmount3 = maxBet3


                if (cloneBetAmount1 > 0 && cloneBetAmount1 < minBet1) cloneBetAmount1 = minBet1
                if (cloneBetAmount2 > 0 && cloneBetAmount2 < minBet2) cloneBetAmount2 = minBet2
                if (cloneBetAmount3 > 0 && cloneBetAmount3 < minBet3) cloneBetAmount3 = minBet3

                const orderModel = {
                    orderNo: id + 1,
                    numberArray: numbersWithoutRemoved,
                    betAmount1: cloneBetAmount1,
                    betAmount2: cloneBetAmount2,
                    betAmount3: cloneBetAmount3,
                    modeDetail,
                }

                setOrder([...order, cloneDeep(orderModel)])
                resetBetEntry()
            } else {
                message.error({ content: "กรุณาใส่ราคาที่จะเล่น" })
            }
        } else {
            message.error({ content: "กรุณาใส่เลข", key: "numbers" })
        }

        document.getElementById(`input-${firstId}`).focus()
    }

    const getOrderDetail = () => {
        if (order.length > 0) {
            let orderDetail = []
            order.forEach((b) => {
                const rates = [lotto.rate.find((r) => r.id === b.modeDetail.key1), lotto.rate.find((r) => r.id === b.modeDetail.key2), lotto.rate.find((r) => r.id === b.modeDetail.key3)]
                let i = 0;
                b.numberArray.forEach((n) => {

                    if (b.modeDetail.hasOwnProperty('key1') && b.betAmount1 !== 0) {

                        const bet1 = {
                            key: `${b.orderNo}-${i}`,
                            modeModal: {
                                mode: b.modeDetail.mode,
                                bet: b.modeDetail.bet1,
                                betNumber: n
                            },
                            mode: b.modeDetail.mode + b.modeDetail.bet1,
                            modeKey: rates[0].id,
                            betNumber: n,
                            betAmount: b.betAmount1,
                            rate: rates[0].rate,
                        }
                        orderDetail.push(calculateClosedNumber(bet1))
                        i++;
                    }
                    if (b.modeDetail.hasOwnProperty('key3')) {
                        if (b.betAmount3 !== 0) {
                            const bet2 = {
                                key: `${b.orderNo}-${i}`,
                                modeModal: {
                                    mode: b.modeDetail.mode,
                                    bet: b.modeDetail.bet2,
                                    betNumber: n
                                },
                                mode: b.modeDetail.mode + b.modeDetail.bet2,
                                modeKey: rates[1].id,
                                betNumber: n,
                                betAmount: b.betAmount3,
                                rate: rates[1].rate,
                            }
                            orderDetail.push(calculateClosedNumber(bet2))
                            i++;
                        }


                        if (b.modeDetail.hasOwnProperty('key2') && b.betAmount2 !== 0) {
                            const bet3 = {
                                key: `${b.orderNo}-${i}`,
                                modeModal: {
                                    mode: b.modeDetail.mode,
                                    bet: b.modeDetail.bet3,
                                    betNumber: n
                                },
                                mode: b.modeDetail.mode + b.modeDetail.bet3,
                                modeKey: rates[2].id,
                                betNumber: n,
                                betAmount: b.betAmount2,
                                rate: rates[2].rate,
                            }
                            orderDetail.push(calculateClosedNumber(bet3))
                            i++;
                        }
                    } else {
                        if (b.modeDetail.hasOwnProperty('key2') && b.betAmount2 !== 0) {
                            const bet2 = {
                                key: `${b.orderNo}-${i}`,
                                modeModal: {
                                    mode: b.modeDetail.mode,
                                    bet: b.modeDetail.bet2,
                                    betNumber: n
                                },
                                mode: b.modeDetail.mode + b.modeDetail.bet2,
                                modeKey: rates[1].id,
                                betNumber: n,
                                betAmount: b.betAmount2,
                                rate: rates[1].rate,
                            }
                            orderDetail.push(calculateClosedNumber(bet2))
                            i++;
                        }
                    }

                })
            })
            return orderDetail;
        }
        return null;
    }

    const getOrderDetailOptimized = async () => {
        if (order.length > 0) {
            let orderDetail = []
            order.forEach((b) => {
                const rates = [lotto.rate.find((r) => r.id === b.modeDetail.key1), lotto.rate.find((r) => r.id === b.modeDetail.key2), lotto.rate.find((r) => r.id === b.modeDetail.key3)]
                let i = 0;
                b.numberArray.forEach((n) => {

                    if (b.modeDetail.hasOwnProperty('key1') && b.betAmount1 !== 0) {
                        const bet1 = {
                            key: b.orderNo + i,
                            mode: b.modeDetail.mode + b.modeDetail.bet1,
                            modes_fk: rates[0].id,
                            betNumber: n,
                            betAmount: b.betAmount1,
                            rate: rates[0].rate,
                        }
                        orderDetail.push(calculateClosedNumber(bet1))
                        i++;
                    }
                    if (b.modeDetail.hasOwnProperty('key3')) {
                        if (b.betAmount3 !== 0) {
                            const bet2 = {
                                key: b.orderNo + i,
                                mode: b.modeDetail.mode + b.modeDetail.bet2,
                                modes_fk: rates[1].id,
                                betNumber: n,
                                betAmount: b.betAmount3,
                                rate: rates[1].rate,
                            }
                            orderDetail.push(calculateClosedNumber(bet2))
                            i++;
                        }


                        if (b.modeDetail.hasOwnProperty('key2') && b.betAmount2 !== 0) {
                            const bet3 = {
                                key: b.orderNo + i,
                                mode: b.modeDetail.mode + b.modeDetail.bet3,
                                modes_fk: rates[2].id,
                                betNumber: n,
                                betAmount: b.betAmount2,
                                rate: rates[2].rate,
                            }
                            orderDetail.push(calculateClosedNumber(bet3))
                            i++;
                        }
                    } else {
                        if (b.modeDetail.hasOwnProperty('key2') && b.betAmount2 !== 0) {
                            const bet2 = {
                                key: b.orderNo + i,
                                mode: b.modeDetail.mode + b.modeDetail.bet2,
                                modes_fk: rates[1].id,
                                betNumber: n,
                                betAmount: b.betAmount2,
                                rate: rates[1].rate,
                            }

                            orderDetail.push(calculateClosedNumber(bet2))
                            i++;
                        }
                    }

                })
            })
            return orderDetail;
        }
        return null;
    }

    const calculateClosedNumber = (betObj) => {
        const closedNumbers = lottoClosedNumber?.closed?.[betObj.mode]
        const halfNumbers = lottoClosedNumber?.half?.[betObj.mode]
        betObj.flag = ''
        if (closedNumbers?.includes(betObj.betNumber)) {
            betObj.rate = 0
            betObj.betAmount = 0
            betObj.flag = 'closed'
        } else if (halfNumbers?.includes(betObj.betNumber)) {
            betObj.rate = Number(betObj.rate) / 2
            betObj.flag = 'half'
        }
        return cloneDeep(betObj)
    }

    const cancelOrder = async (id) => {
        setIsLoading(true)
        try {
            const res = await OrderService.cancelOrder(id)
            if (res === `OK`) {
                message.success({ content: 'ยกเลิกบิลสำเร็จ', key: 'cancelOrderSuccess', duration: 1 });
                userAction.updateBalance()
                const data = await OrderService.getRecentOrders()
                setRecentOrder(data)
                setIsLoading(false)
            } else {
                message.error({ content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", key: "cancelOrder", duration: 1 })
                setIsLoading(false)
            }
        } catch (err) {
            message.error({ content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", key: "cancelOrder", duration: 1 })
            setIsLoading(false)
        }
    }

    const submitOrder = async () => {
        setIsLoading(true)
        setIsModalOrderVisible(false)
        try {
            if (user.balance >= sumBetAmount) {
                const orderDetail = await getOrderDetailOptimized();
                const data = {
                    details: orderDetail,
                    sum: sumBetAmount,
                    remark,
                    lotto_dates_fk: lotto.id,
                    lottos_fk: lotto.lottos_fk,
                    lotto_groups_fk: lotto.groupId,
                    itemCount: orderDetail.length,
                }
                const send = await OrderService.submitOrder(data)
                if (send === `OK`) {
                    await prepareData()
                    resetAll()
                    userAction.updateBalance()
                    await message.success({ content: 'สร้างออร์เดอร์สำเร็จ..', key: 'submitOrderSuccess', duration: 2 });
                } else if (send === `BALANCE_ERROR`) {
                    await message.error({ content: "ยอดเงินคงเหลือไม่พอ กรุณาลองใหม่อีกครั้ง", key: "submitOrder", duration: 2 })
                    userAction.updateBalance()
                } else if (send === `LOTTO_NOT_OPEN`) {
                    await message.error({ content: "หวยไม่เปิดรับ กรุณาลองใหม่อีกครั้ง", key: "submitOrder", duration: 2 })
                    navigate(MENU_ITEMS.homeMember.path)
                }
                else if (send === `RATE_ERROR`) {
                    await message.error({ content: "การตั้งค่าผู้ใช้ผิดพลาด กรุณาติดต่อสายบน", key: "submitOrder", duration: 2 })
                    navigate(MENU_ITEMS.homeMember.path)
                } else {
                    await message.error({ content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", key: "submitOrder", duration: 2 })
                }
                setIsLoading(false)
            } else {
                message.error({ content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", key: "submitOrder" })
            }
        } catch (err) {
            message.error({ content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", key: "submitOrder" })

        }

    }

    const deleteFromOrder = (orderNo) => {
        const cloneOrder = [...order]
        remove(cloneOrder, (b) => {
            return b.orderNo === orderNo
        })
        setOrder(cloneOrder)
    }


    const resetBetEntry = () => {
        resetNumber()
        setBetAmount1(undefined)
        setBetAmount2(undefined)
        setBetAmount3(undefined)
    }

    const resetNumber = () => {
        setNumberArray([])
        setRemovedNumberArray([])
        setSelectedNumbersMode6([])
        setCountMode4(0)
    }

    const resetAll = () => {
        resetBetEntry()
        setOrder([])
        setorderNo(0)
        setRemark("")
    }

    const getModeDetail = (mode, isThai) => {
        let modeDetail = {};
        mode = toNumber(mode)
        switch (mode) {
            case 1:
            case 4:
                modeDetail = {
                    "mode": "2 ตัว",
                    "bet": "บน x ล่าง",
                    "bet1": "บน",
                    "bet2": "ล่าง",
                    "modeKey": "2n",
                    "key1": 5,
                    "key2": 1
                }
                break;
            case 2:
            case 3:
                if (isThai) {
                    modeDetail = {
                        "mode": "3 ตัว",
                        "bet": "บน x ล่าง x โต๊ด",
                        "bet1": "บน",
                        "bet2": "ล่าง",
                        "bet3": "โต๊ด",
                        "modeKey": "3n",
                        "key1": 6,
                        "key2": 2,
                        "key3": 4
                    }
                } else {
                    modeDetail = {
                        "mode": "3 ตัว",
                        "bet": "บน x โต๊ด",
                        "bet1": "บน",
                        "bet2": "โต๊ด",
                        "modeKey": "3n",
                        "key1": 6,
                        "key2": 4
                    }
                }
                break;
            case 5:
                modeDetail = {
                    "mode": "วิ่ง",
                    "bet": "บน x ล่าง",
                    "bet1": "บน",
                    "bet2": "ล่าง",
                    "modeKey": "1run",
                    "key1": 7,
                    "key2": 3
                }
                break;
            case 6:
                if (subMode === "1") {
                    modeDetail = {
                        "mode": "วินเลข",
                        "bet": "บน x ล่าง",
                        "bet1": "บน",
                        "bet2": "ล่าง",
                        "modeKey": "win2",
                        "key1": 5,
                        "key2": 1
                    }
                } else {
                    modeDetail = {
                        "mode": "วินเลข",
                        "bet": "บน x โต๊ด",
                        "bet1": "บน",
                        "bet2": "โต๊ด",
                        "modeKey": "win3",
                        "key1": 6,
                        "key2": 4
                    }
                }
                break;
            default: return modeDetail
        }
        return modeDetail
    }

    const modalHandleOk = () => {
        resetBetEntry()
        setCurrentMode(tabTarget)
        setIsModalVisible(false)
    }

    useEffect(() => {
        prepareData()
    }, [])

    useEffect(() => {
        if (lotto) {
            try {
                let mode = getModeDetail(currentMode, isThai);
                const mode1 = lotto.rate.find((r) => r.id === mode.key1)
                const mode2 = lotto.rate.find((r) => r.id === mode.key2)
                const mode3 = lotto.rate.find((r) => r.id === mode.key3)

                setMaxBet1(toNumber(mode1.max ?? 100000))
                setMaxBet2(toNumber(mode2.max ?? 100000))
                setMaxBet3(toNumber(mode3?.max ?? 100000))

                setMinBet1(toNumber(mode1.min ?? 1))
                setMinBet2(toNumber(mode2.min ?? 1))
                setMinBet3(toNumber(mode3?.min ?? 1))

            }
            catch (err) {
                message.error('เกิดข้อผิดพลาด');
            }

        }
    }, [currentMode, isThai, lotto, subMode])

    const prepareData = async () => {
        try {
            const res = await MemberService.getLottoToBet(lottoDateId)
            if (res === 'INACTIVE') {
                message.info({ content: 'ปิดรับแล้ว', key: 'play-closed' });
                navigate(MENU_ITEMS.homeMember.path)
            } else {
                setLotto(res.lotto);
                setRecentOrder(res.recentOrder)
                setLottoResult(res.lottoResultHistory)
                setLottoClosedNumber(res.lottoClosedNumber)
                setIsThai(res.lotto.groupId === (DB.SYSTEM.LOTTO.GROUP.find((g) => g.name === 'หวยไทย'))?.id)
            }
            setUpdatedAt(dayjs().add(MEMBER_INTERVAL_UPDATE, 'minute'))

        } catch (err) {
            console.log(err)
            message.loading({ content: 'ไม่พบหวย กลับไปหน้าเลือกหวย..', key: 'play-notfound' });
            setTimeout(() => {
                navigate(MENU_ITEMS.homeMember.path)
                message.success({ content: 'ไม่พบหวย กลับไปหน้าเลือกหวย', key: 'play-notfound' });
            }, 2000)
        }
        setIsLoading(false)

    }

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible' && dayjs().isAfter(updatedAt)) {
            console.log("Tab reopened, refetch the data!");
            prepareData()
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, [updatedAt]);


    const columnsLottoResult = [
        {
            title: 'งวด',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: value => (
                DATE_FORMAT.dateonly(value)
            )
        },
        {
            title: '3 ตัวบน',
            dataIndex: 'threeUp',
            key: 'threeUp',
            align: 'center',
            render: (text, record, index) => (
                record.result?.["3 ตัวบน"] ?? '-'
            )
        },
        {
            title: '2 ตัวบน',
            dataIndex: 'twoUp',
            key: 'twoUp',
            align: 'center',
            render: (text, record, index) => (
                record.result?.["2 ตัวบน"] ?? '-'
            )
        },
        {
            title: '2 ตัวล่าง',
            dataIndex: 'twoBelow',
            key: 'twoBelow',
            align: 'center',
            render: (text, record, index) => (
                record.result?.["2 ตัวล่าง"] ?? '-'
            )
        },
    ];

    const getClosedNumber = () => {
        let modes = ["3 ตัวบน", "3 ตัวโต๊ด", "2 ตัวบน", "2 ตัวล่าง", "วิ่งบน", "วิ่งล่าง"];
        let arr = [];
        let i = 0;
        for (const m of modes) {
            let data = {
                id: `closed-${i}`,
                mode: m,
                half: lottoClosedNumber.half?.[m] ?? '',
                closed: lottoClosedNumber.closed?.[m] ?? '',
            }
            i++;
            arr.push(data)
        }
        return arr;
    }

    const dataSourceClosedNumber = lottoClosedNumber ? getClosedNumber() : null
    const columnsClosedNumber = [
        {
            title: 'ประเภท',
            dataIndex: 'mode',
            key: 'mode',
            align: 'center',
            render: (value) => (
                <div style={{ fontWeight: "bold" }}>{value}</div>
            )
        },
        {
            title: 'จ่ายครึ่ง',
            dataIndex: 'half',
            key: 'half',
            width: '40%',
            render: (value) => (
                value + " "
            )
        },
        {
            title: 'เลขปิดรับ',
            dataIndex: 'closed',
            key: 'closed',
            width: '40%',
            render: (value) => (
                value + " "
            )
        }
    ];

    const dataSourcerecentOrder = recentOrder?.length > 0 ? recentOrder : null
    const columnsrecentOrder = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (text, record, index) => (
                index + 1
            )
        },
        {
            title: 'เวลาแทง',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: value => (
                DATE_FORMAT.default(value)
            )

        },
        {
            title: 'ตลาด',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (value, record, index) => {
                return `[${value}] - ${DATE_FORMAT.dateonly(record.date)}`
            }
        },
        {
            title: 'รายการ',
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            render: value => (
                value
            )
        },
        {
            title: 'บาท',
            dataIndex: 'sum',
            key: 'sum',
            align: 'right',
            render: value => (
                NUMBER_FORMAT.default(value)
            )
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'remark',
            key: 'remark',
            align: 'left',
        },
        {
            title: 'ลบโพย',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (value, record) => {
                if (record.status === DB.SYSTEM.ORDER.STATUS.PENDING) {
                    return <Popconfirm
                        title={`ยืนยันการยกเลิกบิลนี้?`}
                        key={record.id}
                        onConfirm={() => cancelOrder(record.id)}
                        okText="ยืนยัน"
                        okType="danger"
                        cancelText="ยกเลิก"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                        <div className="expanded-table-btn"><Button danger type="link"><DeleteOutlined /></Button></div>
                    </Popconfirm>
                }
                if (record.status === DB.SYSTEM.ORDER.STATUS.CANCELED) {
                    return <div className='text-secondary'>โพยถูกยกเลิก</div>
                } else {
                    return order_tag[record.status]
                }
            }
        },

    ];

    return (
        <div className='member-page-group bet-page'>
            <Spin {...ELM_STANDARD.spin} spinning={isLoading}>

                <div>
                    <Link to={MENU_ITEMS.homeMember.path}>
                        <Button type='link' icon={<LeftOutlined />} size="large">
                            เปลี่ยนตลาด
                        </Button>
                    </Link>
                </div>


                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <Alert
                            style={{ marginBottom: "1rem" }}
                            message={<div style={{ fontWeight: 'bold' }}>คำแนะนำ</div>}
                            description={<ul>
                                <li>กดปุ่ม Enter/Tab เพื่อขยับช่องกรอกข้อมูล</li>
                                <li>กดปุ่ม Spacebar เพื่อกลับเลข</li>
                            </ul>}
                            type="info"
                        />

                        <div className='text-secondary text-center font-sarabun cursor-pointer my-2'>
                            <Tooltip placement='top' title={<div className='text-center'>คลิกเพื่ออัปเดต</div>}>
                                <div>
                                    {isLoading ? <SyncOutlined spin />
                                        : <span onClick={prepareData}>อัปเดตล่าสุด: <ClockCircleOutlined /> {DATE_FORMAT.timeonly(dayjs(updatedAt).subtract(MEMBER_INTERVAL_UPDATE, 'minute'))}</span>}
                                </div>
                            </Tooltip>
                        </div>

                        {lotto && (
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col xs={12} >
                                        <div className="play-wrapper" style={lotto?.lottos_fk ? LOTTO_STYLE[lotto.lottos_fk]?.details : {}}>
                                            <Row gutter={[16, 16]}>
                                                <Col style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Image
                                                        src={`../images/lotto/${lotto.lottos_fk}.png`}
                                                        fallback={`../images/lotto/fallback.png`}
                                                        alt=""
                                                        preview={false}
                                                        style={{ maxWidth: '5vw', minWidth: '50px' }} />
                                                </Col>
                                                <Col flex="auto">
                                                    <Title level={4}>[{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === lotto.groupId))?.name}] {lotto.name}</Title>
                                                    <div>{DATE_FORMAT.dateonly(lotto.date)}</div>
                                                    <div style={lotto?.lottos_fk ? { color: LOTTO_STYLE[lotto.lottos_fk]?.details?.color } : {}}>เปิดรับแทงจนถึง {dayjs(lotto.closeAt).format('HH:mm:ss')} น.</div>
                                                    <div className="closetime"> {renderCloseTime()}</div>
                                                </Col>
                                            </Row>
                                            <Row style={{ margin: "1rem 0" }}>
                                                <Button className="playmode-btn-fast" style={lotto?.lottos_fk ? LOTTO_STYLE[lotto.lottos_fk]?.details?.button : {}} icon={<ThunderboltOutlined />} size="large" shape="round">
                                                    แทงเร็ว
                                                </Button>
                                            </Row>
                                            <Row>
                                                <Tabs className="tabs-playmode" style={{ width: '100%' }} type="card" onChange={tabChange} activeKey={currentMode} >
                                                    <TabPane tab="2 ตัว" key="1" >
                                                        {renderNumberArea()}
                                                        <Row gutter={[8, 8]} className="my-2">
                                                            <Col>{inputDoubleNumbers()}</Col>
                                                        </Row>
                                                        <Row gutter={[8, 8]} className="play-inputs-row">
                                                            <Col xs={5}>{inputNumber1(11)}</Col>
                                                            <Col>{putInvertNumbersBtn(12)}</Col>
                                                            <Col xs={7}>{inputBetAmount1("บน", 12)}</Col>
                                                            <Col xs={5}>{inputBetAmount2("ล่าง", 13)}</Col>
                                                            <Col>{btnAddOrder(14, 11)}</Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tab="3 ตัว" key="2" >
                                                        {renderNumberArea()}
                                                        <Row gutter={[8, 8]} className="my-2">
                                                            <Col>{inputTripleNumbers()}</Col>
                                                        </Row>
                                                        <Row gutter={[8, 8]} className="play-inputs-row">
                                                            <Col xs={5}>{inputNumber2(21)}</Col>
                                                            <Col>{putInvertNumbersBtn(22)}</Col>
                                                            <Col xs={7}>{inputBetAmount1("ตรง", 22)}</Col>
                                                            {isThai ? (
                                                                <> <Col xs={5}>{inputBetAmount3("ล่าง", 23)}</Col>
                                                                    <Col xs={5}>{inputBetAmount2("โต๊ด", 24)}</Col></>
                                                            ) :
                                                                <Col xs={5}>{inputBetAmount2("โต๊ด", 23)}</Col>
                                                            }
                                                            <Col>{btnAddOrder(25, 21)}</Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tab="6 กลับ" key="3" >
                                                        {renderNumberArea()}
                                                        <Row gutter={[8, 8]} className="play-inputs-row">
                                                            <Col xs={5}>{inputNumber3(31)}</Col>
                                                            <Col xs={7}>{inputBetAmount1("บน", 32)}</Col>
                                                            <Col>{btnAddOrder(33, 31)}</Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tab="19 ประตู" key="4" >
                                                        {renderNumberArea()}
                                                        <Row gutter={[8, 8]} className="play-inputs-row">
                                                            <Col xs={5}>{inputNumber4(41)}</Col>
                                                            <Col xs={7}>{inputBetAmount1("บน", 42)}</Col>
                                                            <Col xs={7}>{inputBetAmount2("ล่าง", 43)}</Col>
                                                            <Col>{btnAddOrder(44, 41)}</Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tab="เลขวิ่ง" key="5" >
                                                        {renderNumberArea()}
                                                        <Row gutter={[8, 8]} className="play-inputs-row">
                                                            <Col xs={5}>{inputNumber5(51)}</Col>
                                                            <Col xs={7}>{inputBetAmount1("บน", 52)}</Col>
                                                            <Col xs={7}>{inputBetAmount2("ล่าง", 53)}</Col>
                                                            <Col>{btnAddOrder(54, 51)}</Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tab="วินเลข" key="6" >
                                                        {/* render options mode จับวิน */}
                                                        <Row gutter={[8, 8]}>
                                                            <Col xs={24}>
                                                                <Radio.Group onChange={(e) => setSubMode(e.target.value)} value={subMode}>
                                                                    <Radio value="1">จับวิน 2 ตัว</Radio>
                                                                    <Radio value="2">จับวิน 3 ตัว</Radio>
                                                                </Radio.Group>
                                                            </Col>
                                                            <Col xs={24}>
                                                                <Radio.Group onChange={(e) => setSubMode2(e.target.value)} value={subMode2}>
                                                                    <Radio value="1">จับวินไม่รวมเลขเบิ้ล</Radio>
                                                                    <Radio value="2">จับวินรวมเลขเบิ้ล</Radio>
                                                                </Radio.Group>
                                                            </Col>

                                                        </Row>
                                                        <Divider style={{ margin: '0.5rem' }}></Divider>
                                                        <Row gutter={[8, 8]} className="my-2">
                                                            <Col xs={24}>กรุณาเลือกตัวเลขที่ต้องการจับวิน {subMode === "1" ? "2" : "3"} - 5 ตัวเลข</Col>
                                                            <Col xs={12}>
                                                                <Row>
                                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item, index) => {
                                                                        const spareItem = index + 1 === 5 && (<Col key={`spare-${item}`} span={4}></Col>)
                                                                        return [<Col onClick={() => updateNumberMode6(item)} key={item} span={4}><div className={`mode6-number ${selectedNumbersMode6.indexOf(item) !== -1 && 'mode6-selected'}`}>{item}</div></Col>, spareItem]
                                                                    })}
                                                                </Row>
                                                            </Col>
                                                            <Col xs={24}>
                                                                <Row gutter={[4, 4]}>
                                                                    <Col><Button onClick={calculateMode6}>คำนวณ</Button></Col>
                                                                    <Col><Button onClick={reverseMode6Numbers}>กลับเลขวิน</Button></Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        {renderNumberArea()}
                                                        <Row gutter={[8, 8]} className="play-inputs-row">
                                                            {/* case 2 บนล่าง */}
                                                            {subMode === "1" && (<>
                                                                <Col xs={7}>{inputBetAmount1("บน", 61)}</Col>
                                                                <Col xs={7}>{inputBetAmount2("ล่าง", 62)}</Col>
                                                                <Col>{btnAddOrder(63, 61)}</Col>

                                                            </>
                                                            )}
                                                            {/* case 3 บนโต๊ด */}
                                                            {subMode === "2" && (<>
                                                                <Col xs={7}>{inputBetAmount1("บน", 61)}</Col>
                                                                <Col xs={7}>{inputBetAmount2("โต๊ด", 62)}</Col>
                                                                <Col>{btnAddOrder(63, 61)}</Col>
                                                            </>
                                                            )}
                                                        </Row>
                                                    </TabPane>
                                                </Tabs>
                                            </Row>
                                            <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '50vh' }}>
                                                {areaNumberRenderer()}
                                            </div>
                                        </div>

                                        <Divider />
                                        <Row gutter={[16, 16]} className="my-4" justify='center'>
                                            <Col xs={24}><Input addonBefore="หมายเหตุ" value={remark} onChange={(e) => setRemark(e.target.value)} /></Col>
                                            <Col xs={24}>
                                                <Row gutter={[8, 8]} justify="center text-big">
                                                    <Col className='text-blue'>[{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === lotto.groupId))?.name}] {lotto.name}</Col>
                                                    <Col className='text-red'>{DATE_FORMAT.dateonly(lotto.date)}</Col>
                                                </Row>
                                            </Col>

                                            <Col xs={24} className="text-center text-title">รวม : {NUMBER_FORMAT.default(sumBetAmount)} บาท</Col>
                                        </Row>
                                        <Row gutter={[16, 16]} justify="center" style={{ marginTop: "1rem" }}>
                                            <Col>
                                                <Button size='large' danger type='primary' icon={<ClearOutlined />} onClick={() => resetNumber()}>  ล้างตาราง
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button size='large' type="primary" icon={<SaveOutlined />} disabled={sumBetAmount === 0 || sumBetAmount > user.balance}
                                                    onClick={() => {
                                                        setIsModalOrderVisible(true);
                                                        setModalDataSource(getOrderDetail())
                                                    }
                                                    }>
                                                    {sumBetAmount > user.balance ? `ยอดรวมเกินวงเงิน` : `บันทึก`}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>



                                    <Col xs={12} className="table-section">
                                        <div className='table-wrapper'>
                                            <Table
                                                className="table-elm"
                                                rowKey={(record) => record.id}
                                                title={() => <div><HistoryOutlined /> สถิติผลรางวัล (3 งวดล่าสุด)</div>}
                                                // footer={() => <div style={{ textAlign: "center" }}><Link to={{ pathname: lottoResultLink(lotto.id) }} target="_blank"><LinkOutlined /> ไปยังหน้าผลหวย</Link></div>}
                                                scroll={{ x: 'max-content' }}
                                                dataSource={lottoResult}
                                                columns={columnsLottoResult}
                                                bordered
                                                pagination={false}
                                                size="small"
                                            /></div>
                                        <div className='table-wrapper'>
                                            <Table
                                                className="table-elm"
                                                scroll={{ x: 'max-content' }}
                                                rowKey={(record) => record.id}
                                                title={() => <div><RocketOutlined /> เลขมาแรง</div>}
                                                dataSource={dataSourceClosedNumber}
                                                columns={columnsClosedNumber}
                                                bordered
                                                pagination={false}
                                                size="small"
                                            />
                                        </div>
                                        <div className='table-wrapper'>
                                            <Table
                                                rowKey={(record) => record.id}
                                                className="table-elm"
                                                scroll={{ x: 'max-content' }}
                                                size="small"
                                                title={() => <div><SolutionOutlined /> รายการบิลล่าสุด   </div>}
                                                dataSource={dataSourcerecentOrder}
                                                columns={columnsrecentOrder}
                                                pagination={false}
                                                footer={() => <div style={{ textAlign: "center" }}><Link to="/orders">แสดงรายการทั้งหมด</Link></div>}
                                                rowClassName={(record) => record.status === DB.SYSTEM.ORDER.STATUS.CANCELED ? 'text-red' : ''}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: (e) => {
                                                            if (e.target.cellIndex && e.target.cellIndex !== 6) {
                                                                setModalOrderDetailData(record)
                                                                setIsModalOrderDetailVisible(true)
                                                            }

                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                <Modal  {...ELM_STANDARD.noTransitionModal}
                                    style={{ minWidth: '50vw' }}
                                    title="กรุณายืนยันรายการ" cancelText="ยกเลิก" okText="ยืนยัน" visible={isModalOrderVisible} onOk={submitOrder} onCancel={() => setIsModalOrderVisible(false)}>
                                    <Table
                                        rowKey={(record) => record.key}
                                        title={() => `${lotto.name} | งวด ${DATE_FORMAT.dateonly(lotto.date)}`}
                                        dataSource={modalDataSource}
                                        scroll={{ y: '50vh', x: 'max-content' }}
                                        columns={[
                                            {
                                                title: 'ประเภท @ หมายเลข',
                                                dataIndex: 'modeModal',
                                                key: 'modeModal',
                                                align: 'center',
                                                render: value => (
                                                    <div style={{ textAlign: "center" }}><span className="color-red">{value.mode}{value.bet}</span> @ <span className="color-blue">{value.betNumber}</span></div>
                                                )
                                            },
                                            {
                                                title: 'ยอดเดิมพัน',
                                                dataIndex: 'betAmount',
                                                key: 'betAmount',
                                                align: 'right',
                                                render: (value, record) => (
                                                    record.flag === 'closed' ? <span className='text-red'>{NUMBER_FORMAT.default(value)}</span> : NUMBER_FORMAT.default(value)
                                                )
                                            },
                                            {
                                                title: 'เรทจ่าย',
                                                dataIndex: 'rate',
                                                key: 'rate',
                                                align: 'center', render: (value, record) => (
                                                    record.flag ? <span className='text-red'>{value}</span> : value
                                                )

                                            },
                                            {
                                                title: 'หมายเหตุ',
                                                dataIndex: 'flag',
                                                key: 'flag',
                                                align: 'center',
                                                render: value => {
                                                    return close_number_tag[value]
                                                }
                                            },
                                        ]}
                                        bordered
                                        pagination={false}
                                        summary={pageData => {
                                            let sum1 = 0;
                                            let i = 0;

                                            pageData.forEach(({ betAmount, discountAmount }) => {
                                                sum1 += betAmount
                                                i++;
                                            });
                                            return (
                                                <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                                    <Table.Summary.Cell className='text-center'><div >รวม @ {i} รายการ</div></Table.Summary.Cell>
                                                    <Table.Summary.Cell className='text-right text-green' colSpan={3}><div>{NUMBER_FORMAT.default(sum1)} บาท</div> </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            );
                                        }}
                                    />
                                </Modal>

                                <OrderDetail
                                    visible={isModalOrderDetailVisible}
                                    onClose={() => setIsModalOrderDetailVisible(false)}
                                    data={modalOrderDetailData}
                                />

                                <Modal title="คำเตือน" cancelText="ยกเลิก" okText="ยืนยัน" okType="default" okButtonProps={{ icon: <CheckOutlined /> }} visible={isModalVisible} onOk={modalHandleOk} onCancel={() => setIsModalVisible(false)}>
                                    <p>หากเปลี่ยนโหมด ข้อมูลที่ยังไม่บันทึกจะหายไปทันที</p>
                                    <p>กรุณายืนยัน</p>
                                </Modal>
                            </>
                        )}
                    </div>
                </div >
            </Spin >
        </div >

    )
}

export default Bet;